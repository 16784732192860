import React from 'react';
import Question from './Question';
import VideoBG from './VideoBG';
import { useStore } from '../contexts/store';
import { mixpanelTrack } from '../utils/mixpanel';
import {
  Level,
  Answerable,
  Character,
  QuestionGenerator,
  Session,
} from '../gameplay';

type LevelViewProps = {
  levelId: number;
  questions: QuestionGenerator;
  enemyCssClass: string;
  enemyImg: string;
  levelImg: string;
  winVideo?: string;
  onGameOver: () => void;
  onWin: () => void;
};

const playerStartingHP = 100;
const enemyStartingHP = 100;
const timeLimit = 10000 // in ms, 10 seconds

const LevelView = (props: LevelViewProps) => {
  const {
    levelId,
    questions,
    enemyCssClass,
    enemyImg,
    levelImg,
    winVideo,
    onGameOver,
    onWin,
  } = props;

  const [playerHp, setPlayerHp] = React.useState(playerStartingHP);
  const [playerHit, setPlayerHit] = React.useState(false);
  const [enemyHp, setEnemyHp] = React.useState(enemyStartingHP);
  const [enemyHit, setEnemyHit] = React.useState(false);
  const [pctTimeLeft, setPctTimeLeft] = React.useState(100);
  const [showWinVideo, setShowWinVideo] = React.useState(false);
  const [levelScore, setLevelScore] = React.useState(0);

  const [level, setLevel] = React.useState<Level | null>(null);
  const [session, setSession] = React.useState<Session | null>(null);
  const [questionIdx, setQuestionIdx] = React.useState(-1);

  const [appState, dispatch] = useStore();

  // flash player when hit
  React.useEffect(() => {
    const flashTime = 100; //ms
    setPlayerHit(true);

    const timeout = setTimeout(() => {
      setPlayerHit(false);
    }, flashTime);

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [playerHp]);

  // flash enemy when hit
  React.useEffect(() => {
    const flashTime = 100; //ms
    setEnemyHit(true);

    const timeout = setTimeout(() => {
      setEnemyHit(false);
    }, flashTime);

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [enemyHp]);

  React.useEffect(() => {
    if (playerHp <= 0 || enemyHp <= 0) {
      dispatch({
        type: 'updateScore',
        levelScore: levelScore,
      });
      
      if (playerHp <= 0) {
        onGameOver();
      } else if (enemyHp <= 0) {
        winVideo ? setShowWinVideo(true) : onWin();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerHp, enemyHp]);

  // on mount
  React.useEffect(() => {
    function onPlayerDamage(remainingHp: number): void {
      mixpanelTrack(appState, 'player-damage', {
        levelId: levelId,
        remainingHp: remainingHp,
      });
      setPlayerHp(remainingHp);
    }

    function onEnemyDamage(remainingHp: number): void {
      mixpanelTrack(appState, 'enemy-damage', {
        levelId: levelId,
        remainingHp: remainingHp,
      });
      setEnemyHp(remainingHp);
    }

    const player = new Character(onPlayerDamage, playerStartingHP);
    const enemy = new Character(onEnemyDamage, enemyStartingHP);
    const initLevel = new Level(player, enemy, questions, timeLimit);

    setLevel(initLevel);
    setQuestionIdx(0);
  }, [appState, levelId, questions]);

  // when showWinVideo becomes true, the session timer needs to be cancelled, because it's still ticking
  React.useEffect(() => {
    if (showWinVideo && session) {
      session.cancel();
    }
  }, [session, showWinVideo]);

  // whenever question changes, start a new session / timer
  React.useEffect(() => {
    function onTick(remaining: number) {
      if (remaining > 0) {
        setPctTimeLeft(Math.floor(100 * remaining / timeLimit));
      } else if (level) {
        setQuestionIdx(questionIdx + 1);
      }
    }

    if (level) {
      setSession(level.startSession(level.getQuestions()[questionIdx], onTick));
      setPctTimeLeft(100);
    }
  }, [questionIdx, level]);

  function answer(question: Answerable, idx: number): boolean {
    if (level && session) {
     const damage = level.answerQuestion(session, question, idx);
     const points = damage * 10 * (questions.questions().length - questionIdx);
     setLevelScore(levelScore + points);
     setQuestionIdx(questionIdx + 1);
     return damage > 0;
    }
    return false;
  }

  function endWinVideo(): void {
    onWin();
  }

  return (
    <>
      {
        showWinVideo ? (
          <VideoBG
            movie={winVideo}
            loop={false}
            type="video/mp4"
            onEnded={endWinVideo}
            soundBtnClassName="intro-sound-button"
          />
        ) : questions.questions().map((question, idx) => {
          return (
            idx === questionIdx && <Question
              key={idx}
              onAnswer={answer}
              enemyCssClass={enemyCssClass}
              enemyHit={enemyHit}
              enemyHp={enemyHp}
              enemyImg={enemyImg}
              levelImage={levelImg}
              playerHp={playerHp}
              playerHit={playerHit}
              question={question}
              pctTimeLeft={pctTimeLeft}
              levelScore={levelScore}
            />
          );
        })
      }
    </>
  );
};

export default LevelView;
