import * as React from "react";
import { useStore } from '../contexts/store';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeDown } from '@fortawesome/free-solid-svg-icons'
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons'

import './SoundButton.css';


type SoundButtonIconProp = {
  soundOn: boolean;
}

const SoundButtonIcon = (props: SoundButtonIconProp) => {
  return (
    <FontAwesomeIcon icon={props.soundOn ? faVolumeDown : faVolumeMute} />
  );
}


type SoundButtonProp = {
  className: string;
  onClick?: (event: object) => void;
}

const SoundButton = (props: SoundButtonProp) => {
  const [{soundOn}, dispatch] = useStore();

  function handleClick(event: object) {
    if (props.onClick) {
      props.onClick(event);
    }

    dispatch({type: 'toggleSound'});
  }

  return (
    <button className={props.className} onClick={handleClick}>
      <SoundButtonIcon soundOn={soundOn} />
    </button>
  );
}


export default SoundButton;
