import React from 'react';
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  RedditIcon,
  TwitterIcon,
} from 'react-share';
import { mixpanelTrackLinks } from '../utils/mixpanel';

import './SocialShare.css';
import { useStore } from '../contexts/store';

export default function SocialShare() {
  const [appState] = useStore();
  const { name, score } = appState;

  const shareMsg = 'Can you save the world and beat my score on Godzillion?';
  const shareUrl = `https://www.godzillion.net/?challenger=${name}&beat=${score}`;

  React.useEffect(() => {
    mixpanelTrackLinks(appState, '#facebook-share-btn', 'clicked-share-with-fb');
    mixpanelTrackLinks(appState, '#twitter-share-btn', 'clicked-share-with-twitter');
    mixpanelTrackLinks(appState, '#reddit-share-btn', 'clicked-shared-with-reddit');
  });

  return (
    <div className="share-buttons">
      <p>Challenge Your Friends!&nbsp;</p>
      <FacebookShareButton url={shareUrl} quote={shareMsg} id="facebook-share-btn">
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} title={shareMsg} id="twitter-share-btn">
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <RedditShareButton url={shareUrl} title={shareMsg} windowWidth={660} windowHeight={460} id="reddit-share-btn">
        <RedditIcon size={32} round />
      </RedditShareButton>
    </div>
  );
}