import React from 'react';
import { useStore } from '../contexts/store';
import './VideoBG.css';

import SoundButton from './SoundButton';

export default function VideoBG(props) {
  const {
    movie,
    loop,
    type,
    onClick,
    onEnded,
    children,
    soundBtnClassName
  }= props;
  const videoRef = React.createRef();
  const [{soundOn}, dispatch] = useStore();

  const handleSoundBtnClick = (e) => {
    e.stopPropagation();
  }

  React.useEffect(() => {
    async function playVideo() {
      const video = await videoRef.current;
      const promise = video.play();

      promise.then(_ => {
        // playing
      }).catch(error => {
        dispatch({type: 'toggleSound'});
      })
    }
    playVideo();
  }, [videoRef, dispatch]);

  return (
    <div className="wrapper" onClick={onClick}>
      <div className="media-container">
        <video loop={loop} ref={videoRef} onEnded={onEnded} playsInline muted={!soundOn} >
          <source src={movie} type={type} />
        </video>
      </div>
      {children}
      <SoundButton className={soundBtnClassName} onClick={handleSoundBtnClick}/>
    </div>
  );
}
