import * as React from "react";
import './QuestionBox.css';

type QuestionBoxProp = {
  text: string
};

const QuestionBox = (props: QuestionBoxProp) => {
  return (
    <div className="question-box">
      <p>
        {props.text}
      </p>
    </div>
  );
}

export default QuestionBox;
