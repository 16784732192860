import * as React from "react";
import './Question.css';
import ImageBG from './ImageBG';

import { MultipleChoiceQuestion, WriteInQuestion, Answerable } from '../gameplay';

import QuestionBox from './QuestionBox';
import MultipleChoiceAnswerBox from './MultipleChoiceAnswerBox';
import TimerBar from "./Timer";
import NavBar from './NavBar';

import SpaceshipImg from '../assets/PenOne.png';

type QuestionProp = {
  enemyCssClass: string;
  enemyHit: boolean;
  enemyHp: number;
  enemyImg: string;
  levelImage: string;
  onAnswer: (question: Answerable, answer: number) => boolean;
  playerHit: boolean;
  playerHp: number;
  question: MultipleChoiceQuestion | WriteInQuestion;
  pctTimeLeft: number;
  levelScore: number;
}

// TODO MultipleChoiceQuestion for now. Need to make generic for WriteInQuestion after
const Question = (props: QuestionProp) => {
  const handleAnswerBoxClick = (index: number) => {
    props.onAnswer(props.question, index);
  };

  function isMultipleChoiceQuestion(question: WriteInQuestion | MultipleChoiceQuestion):
    question is MultipleChoiceQuestion {
      return (question as MultipleChoiceQuestion).choices !== undefined;
  }

  return (
    <ImageBG name={props.levelImage} type="image/jpeg">
      <div className="question">
        <NavBar levelScore={props.levelScore} />
        <div className="question-container">
          <QuestionBox text={props.question.text} />
          <div className="answers">
            { isMultipleChoiceQuestion(props.question) ?
                props.question.choices.map( (choice, i) =>
                  <MultipleChoiceAnswerBox
                    key={i}
                    index={i}
                    onClick={handleAnswerBoxClick}
                    text={choice}
                  />
                ) :
                null // WriteInQuestionComponet Will go here eventually
            }
          </div>
          <div className="timer-bar">
            <TimerBar percentage={props.pctTimeLeft} />
          </div>
          <div className={props.enemyCssClass}>
            <div className="enemy-hp-box">HP: {props.enemyHp}</div>
            <img className="sprite-img" src={props.enemyImg} alt="alien" />
          </div>
          <div className="spaceship">
            <img className="sprite-img" src={SpaceshipImg} alt="spaceship" />
            <div className="hp-box">HP: {props.playerHp}</div>
          </div>
        </div>
      </div>
    </ImageBG>
  )
};

export default Question;
