import React from 'react';
import LevelView from './LevelView';
import GameOver from './GameOver';
import YouWin from './YouWin';
import { useStore } from '../contexts/store';
import { mixpanelTrack } from '../utils/mixpanel';
import { levelOneQuiz, levelTwoQuiz, levelThreeQuiz, levelFourQuiz } from '../quizData/levelOneQuizV1';

import AlienImg from '../assets/Tailfire.png';
import LevelImg from '../assets/LevelOne.jpg';
import BatAssMonsterImg from '../assets/FlyingCreature.png';
import BatAssMonsterLevelImg from '../assets/Level03.jpg';
import MouthfulMonsterImg from '../assets/Monster1Idle.png';
import MouthfulMonsterLevelImg from '../assets/Level05.jpg';
import BossLevelImg from '../assets/LevelSeven.jpg';
import BossImg from '../assets/BossMonsterAttack.png';
import Level1End from '../assets/Level1End.mp4';
import Level2End from '../assets/Level2End.mp4';
import Level3End from '../assets/Level3End.mp4';

const Levels = () => {
  const [levelIdx, setLevelIdx] = React.useState(0);
  const [alive, setAlive] = React.useState(true);
  const [appState] = useStore();

  function onGameOver(): void {
    mixpanelTrack(appState, 'lose', {
      levelIdx: levelIdx,
    });
    setAlive(false);
  }

  function onWin(): void {
    mixpanelTrack(appState, 'win-level', {
      levelIdx: levelIdx,
    });
    setLevelIdx(levelIdx + 1);
  }

  const levels = [
    <LevelView
      key={0}
      levelId={0}
      questions={levelOneQuiz}
      enemyCssClass='alien'
      enemyImg={AlienImg}
      levelImg={LevelImg}
      onGameOver={onGameOver}
      onWin={onWin}
      winVideo={Level1End}
    />,
    <LevelView
      key={1}
      levelId={1}
      questions={levelTwoQuiz}
      enemyCssClass='alien'
      enemyImg={BatAssMonsterImg}
      levelImg={BatAssMonsterLevelImg}
      onGameOver={onGameOver}
      onWin={onWin}
      winVideo={Level2End}
    />,
    <LevelView
      key={2}
      levelId={2}
      questions={levelThreeQuiz}
      enemyCssClass='alien'
      enemyImg={MouthfulMonsterImg}
      levelImg={MouthfulMonsterLevelImg}
      onGameOver={onGameOver}
      onWin={onWin}
      winVideo={Level3End}
    />,
    <LevelView
      key={3}
      levelId={3}
      questions={levelFourQuiz}
      enemyCssClass='boss'
      enemyImg={BossImg}
      levelImg={BossLevelImg}
      onGameOver={onGameOver}
      onWin={onWin}
    />,
  ];

  if (levelIdx >= levels.length) {
    return <YouWin />;
  }

  if (alive) {
    return levels[levelIdx];
  }

  return <GameOver />;
};

export default Levels;
