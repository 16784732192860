import React from 'react';
import { useStore } from '../contexts/store';
import VideoBG from './VideoBG';
import SocialShare from './SocialShare';
import RestartButton from './RestartButton';
import GameOverVideo from '../assets/GameOverParachute.mp4';
import { SpaceShipOneLinkCentered } from './SpaceshipOneLink';

import './VideoBG.css';

const GameOver = () => {
  const [{score}] = useStore();

  return (
    <>
      <VideoBG
        movie={GameOverVideo}
        loop={false}
        type="video/mp4"
        soundBtnClassName="intro-sound-button"
        onClick={null}
      >
        <div className="game-over-text">
          <div>Game Over</div>
          <div>Score: {score}</div>
        </div>
        <SocialShare />
        <SpaceShipOneLinkCentered />
        <div className="flex-centered">
          <RestartButton />
        </div>
      </VideoBG>
    </>
  );
}

export default GameOver;
