import { MultipleChoiceQuestion } from '../gameplay';

function shuffle(shuffleArray: Array<any>) {
  var j, x, i;
    for (i = shuffleArray.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = shuffleArray[i];
        shuffleArray[i] = shuffleArray[j];
        shuffleArray[j] = x;
    }
    return shuffleArray;
}

// Arguments: (public text: string), (public answerIdx: number), (public choices: string[])

// Level 1: MULTIPLICATION (Multidigit, Round Numbers)
const levelOneQuiz = {
  shuffled: shuffle([
    new MultipleChoiceQuestion(`What’s 10 x 100?`, 2, ['10', '100', '1,000', '10,000']), // 1,000
    new MultipleChoiceQuestion(`What's 300 x 20?`, 3, ['50', '60', '500', '6,000']), // 6,000
    new MultipleChoiceQuestion(`What's 25 x 40?`, 0, ['1,000', '1,500', '12,500', '25,000']), // 1,000
    new MultipleChoiceQuestion(`What’s 30 x 100?`, 1, ['300', '3,000', '30,000', '300,000']), // 3,000
    new MultipleChoiceQuestion(`What's 1,000 x 150?`, 2, ['1,500', '15,000', '150,000', '1,500,000']), // 150,000
    new MultipleChoiceQuestion(`What's 300 x 300?`, 3, ['600', '900', '9,000', '90,000']), // 90,000 
    new MultipleChoiceQuestion(`What's 5 x 4,000?`, 2, ['2,000', '9,000', '20,000', '200,000']), // 20,000 
  ]),
  questions() {
    return levelOneQuiz.shuffled;
  },
};

// Level 2: DIVISION (Multidigit, Round Numbers)
const levelTwoQuiz = {
  shuffled: shuffle([
    new MultipleChoiceQuestion(`What’s 1,000 ÷ 10?`, 1, ['10', '100', '1,000', '10,000']), // 100
    new MultipleChoiceQuestion(`What's 6,000 ÷ 20?`, 2, ['30', '75', '300', '12,000']), // 300
    new MultipleChoiceQuestion(`What's 1,000 ÷ 25?`, 0, ['40', '125', '400', '25,000']), // 40
    new MultipleChoiceQuestion(`What’s 3,000 ÷ 10?`, 1, ['30', '300', '3,000', '30,000']), // 300
    new MultipleChoiceQuestion(`What's 150,000 ÷ 1,000?`, 0, ['150', '1,500', '15,000', '150,000']), // 150
    new MultipleChoiceQuestion(`What's 90,000 ÷ 300?`, 1, ['30', '300', '3,000', '12,000']), // 300 
    new MultipleChoiceQuestion(`What's 20,000 ÷ 4,000?`, 0, ['5', '50', '500', '5,000']), // 5 
  ]),
  questions() {
    return levelTwoQuiz.shuffled;
  },
};

// Level 3: APPROXIMATION & Orders of Magnitude -- Mix of Multiplication & Division (Multidigit, Round Numbers)
const levelThreeQuiz = {
  shuffled: shuffle([
    new MultipleChoiceQuestion(`300 x 100 -- Which answer is closest?`, 2, ['200', '2,000', '20,000', '200,000']), // 30,000
    new MultipleChoiceQuestion(`6,000 ÷ 200 -- Which answer is closest?`, 0, ['50', '500', '5,000', '50,000']), // 300
    new MultipleChoiceQuestion(`2000 x 45 -- Which answer is closest?`, 3, ['100', '1,000', '10,000', '100,000']), // 90,000  
    new MultipleChoiceQuestion(`3,000 ÷ 15 -- Which answer is closest?`, 2, ['3', '30', '300', '3,000']), // 200
    new MultipleChoiceQuestion(`700 x 300 -- Which answer is closest?`, 3, ['100', '1,000', '10,000', '100,000']), // 210,000
    new MultipleChoiceQuestion(`9,000 ÷ 30 -- Which answer is closest?`, 1, ['50', '500', '5,000', '50,000']), // 300
    new MultipleChoiceQuestion(`800 x 400 -- Which answer is closest?`, 3, ['300', '3,000', '30,000', '300,000']), // 320,000             
  ]),
  questions() {
    return levelThreeQuiz.shuffled;
  },
};

// Level 4: Numbers in WORD FORM: Multiplication & Division (Multidigit, Round Numbers)
const levelFourQuiz = {
  shuffled: shuffle([
    new MultipleChoiceQuestion(`What's a Thousand times a Thousand?`, 2, ['2 Thousand', '100 Thousand', '1 Million', '10 Million']), // 1 Million
    new MultipleChoiceQuestion(`What's a Million divided by a Hundred?`, 0, ['10 Thousand', '100 Thousand', '10 Million', '100 Million']), // 10,000
    new MultipleChoiceQuestion(`What's a Hundred times a Hundred Thousand?`, 3, ['1 Thousand', '10 Thousand', '1 Million', '10 Million']), // 10 Million
    new MultipleChoiceQuestion(`What's 25 Million divided by a Thousand?`, 1, ['25 Hundred', '25 Thousand', '25 Million', '25 Billion']), // 25 Thousand
    new MultipleChoiceQuestion(`What's 10 Million times a Hundred?`, 2, ['100 Thousand', '100 Million', '1 Billion', '1 Trillion']), // 1 Billion
    new MultipleChoiceQuestion(`What's a Million times a Million?`, 3, ['1', '1 Million', '100 Billion', '1 Trillion']), // 1 Trillion
    new MultipleChoiceQuestion(`What's a Billion divided by a Thousand?`, 1, ['1 Thousand', '1 Million', '10 Billion', '1 Trillion']), // 1 Million           
  ]),
  questions() {
    return levelFourQuiz.shuffled;
  },
};

export { levelOneQuiz, levelTwoQuiz, levelThreeQuiz, levelFourQuiz };
