import React from 'react';

import './SpaceshipOneLink.css';
import SpaceshipOneImg from '../assets/spaceshipone-icon.png'

const SpaceshipOneLink = ()  => {
  return (
    <div className="spaceship-one">
      <a href="https://www.spaceshipone.org/" className="spaceship-one-link">
        <img src= {SpaceshipOneImg} alt="spaceship-one-logo"/>
        <div >SpaceshipOne</div>
      </a>
    </div>
  );
}

export const SpaceShipOneLinkForIntro = () => {
  return (
    <div className="spaceship-one-intro">
      <SpaceshipOneLink />
    </div>
  );
}

export const SpaceShipOneLinkCentered = () => {
  return (
    <div className="spaceship-one-centered">
      <SpaceshipOneLink />
    </div>
  );
}

export default SpaceshipOneLink;
