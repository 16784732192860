import React from 'react';
import './NameForm.css';
import { useStore } from '../contexts/store';

export default function NameForm({onClick}) {
  const [, dispatch] = useStore();
  const [nameVal, setNameVal] = React.useState('');
  const inputRef = React.createRef();
  const handleChange = (event) => {
    setNameVal(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch({type: 'setName', value: nameVal});
    onClick();
  };

  React.useEffect(() => inputRef.current.focus());

  return (
    <form className="name-entry" onSubmit={handleSubmit}>
      <label className="name-entry-label">ENTER YOUR NAME</label>
      <input type="text" ref={inputRef} onChange={handleChange}></input>
      <button type="submit" className="name-entry-btn">Play</button>
    </form>
  );
}
