import React from 'react';
import Game from './components/Game';
import { StoreProvider } from './contexts/store';
import { mixpanelInit } from './utils/mixpanel';

function initialStoreState(initData) {
  return {
    ...initData,
    soundOn: true,
    mixpanel: mixpanelInit(),
    name: '',
    score: 0,
  };
};

function reducer(state, action) {
  switch(action.type) {
    case 'updateScore':
      const newScore = state.score + action.levelScore;

      return {
        ...state,
        score: newScore,
      };
    case 'toggleSound':
      return {
        ...state,
        soundOn: !state.soundOn,
      };
    case 'setName':
      return {
        ...state,
        name: action.value,
      };
    default:
      return state;
  }
}

export default function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const challenge = {
    challenger: queryParams.get('challenger'),
    beat: queryParams.get('beat'),
  };

  return (
    <StoreProvider initialState={initialStoreState(challenge)} reducer={reducer}>
      <Game />
    </StoreProvider>
  );
}
