
import * as React from "react";
import './QuestionBox.css';

type MultipleChoiceProp = {
  index: number;
  text: string;
  onClick: (index: number) => void;
}

const MultipleChoiceAnswerBox = (props: MultipleChoiceProp) => {
  const handleClick = () => props.onClick(props.index);

  return (
    <div className="answer-box" onClick={handleClick}>
      <p>{props.text}</p>
    </div>
  );
}

export default MultipleChoiceAnswerBox;
