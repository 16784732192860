import React, {createContext, useContext, useReducer} from 'react';

const StoreContext = createContext();

function StoreProvider({reducer, initialState, children}) {
  return (
    <StoreContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StoreContext.Provider>
  );
}

function useStore() {
  return useContext(StoreContext);
}

export { StoreContext, StoreProvider, useStore };