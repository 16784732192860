import * as React from "react";
import { useStore } from '../contexts/store';
import './RestartButton.css';
import { mixpanelTrack } from '../utils/mixpanel';

const RestartButton = () => {
  const [appState] = useStore();
  
  const handleClick = (): void => {
    mixpanelTrack(appState, 'restart-game', {});
    window.location.reload();
  };

  return (
    <button className="restart-btn" onClick={handleClick}>
      Restart Game
    </button>
  );
}

export default RestartButton;