import React from 'react';
import './VideoBG.css';

export default function ImageBG(props) {
  const {
    name,
    type,
    children,
    onClick
  }= props;

  return (
    <div className="wrapper" onClick={onClick}>
      <div className="media-container" >
        <img src={name} type={type} alt="background screen" />
      </div>
      {children}
    </div>
  );
}
