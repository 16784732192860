const defaultHp = 100;
const defaultMinDamage = 20;

class Character {
  initialHp: number;

  constructor(
    public onDamage: (remainingHp: number) => void,
    public hp = defaultHp,
    public minDamage = defaultMinDamage) {
      this.initialHp = hp;
  }

  hit(damage: number): void {
    if (damage < this.minDamage) {
      damage = this.minDamage;
    }
    
    this.hp -= damage;
    if (this.hp <= 0) {
      this.hp = 0;
    }

    this.onDamage(this.hp);
  }

  percentHp(): number {
    return Math.floor(100 * (this.hp / this.initialHp)); 
  }
}

const defaultTimeLimit = 10000; // 10 seconds
const defaultTickMillis = 50; // 20x / sec

class Session {
  timer?: ReturnType<typeof setTimeout>;

  constructor(
    public millisRemaining: number,
    public tickMillis: number,
    public onTick: (remaining: number) => void,
    public onExpire: () => void) {
      this.timer = undefined;
  }

  start(): void {
    const tickHandler = () => {
      this.millisRemaining -= this.tickMillis;

      if (this.millisRemaining <= 0) {
        this.onExpire();
        this.millisRemaining = 0;
        this.timer = undefined;
      } else {
        this.timer = setTimeout(tickHandler, this.tickMillis);
      }

      this.onTick(this.millisRemaining);
    };

    this.timer = setTimeout(tickHandler, this.tickMillis);
  }

  cancel(): number {
    this.timer && clearTimeout(this.timer);
    const remaining = this.millisRemaining;
    this.millisRemaining = 0;
    this.timer = undefined;

    return remaining;
  }

  active(): boolean {
    return typeof this.timer !== 'undefined';
  }
}

class Level {
  constructor(
    public player: Character,
    public enemy: Character,
    public questions: QuestionGenerator,
    public timeLimit = defaultTimeLimit,
    public tickMillis = defaultTickMillis) {
  }

  getQuestions(): Array<MultipleChoiceQuestion | WriteInQuestion> {
    return this.questions.questions();
  }

  startSession(question: Answerable, onTick: (remaining: number) => void): Session {
    const session = new Session(this.timeLimit, this.tickMillis, onTick, () => {
      this.player.hit(question.damage);
    });
    session.start();
    return session;
  }

  answerQuestion(session: Session, question: Answerable, answer: number): number {
    // accept no answers while not on the clock
    if (!session.active()) {
      return 0;
    }

    const remaining = session.cancel();

    if (question.checkAnswer(answer)) {
      const damage = Math.floor(question.damage * (remaining / this.timeLimit));
      this.enemy.hit(damage);
      return damage;
    }

    this.player.hit(question.damage);
    return 0;
  }
}

export interface Answerable {
  damage: number;
  checkAnswer: (answer: number) => boolean;
};

export interface QuestionGenerator {
  questions: () => Array<WriteInQuestion | MultipleChoiceQuestion>;
}

const defaultDamage = 50;

class WriteInQuestion {
  damage: number;
  constructor(public text: string,
    public answer: number,
    public min: number,
    public max: number) {
      this.damage = defaultDamage;
  }

  checkAnswer(answer: number): boolean {
    return answer >= this.min && answer <= this.max;
  }
}

class MultipleChoiceQuestion {
  damage: number;
  constructor(public text: string,
    public answerIdx: number,
    public choices: string[]) {
      this.damage = defaultDamage;
  }

  checkAnswer(answer: number): boolean {
    return answer === this.answerIdx;
  }
}

export { Character, Level, Session, MultipleChoiceQuestion, WriteInQuestion };