import React from 'react';

import './Timer.css';

type TimerBarProps = {
  percentage: number;
};

const TimerBar = (props: TimerBarProps) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};

const Filler = (props: TimerBarProps) => {
  return <div className="filler" style={{ width: `${props.percentage}%` }} />;
};

export default TimerBar;