import React from 'react';
import { useStore } from '../contexts/store';
import { mixpanelTrack } from '../utils/mixpanel';
import SocialShare from './SocialShare';
import VideoBG from './VideoBG';
import RestartButton from './RestartButton';
import { SpaceShipOneLinkCentered } from './SpaceshipOneLink';

import YouWinVideo from '../assets/FinalVideo.mp4'

export default function YouWin() {
  const [appState] = useStore();
  const { score } = appState;

  React.useEffect(() => {
    mixpanelTrack(appState, 'win-game', {
      userScore: score,
    });
  });

  return (
    <VideoBG
      movie={YouWinVideo}
      loop={false}
      type="video/mp4"
      soundBtnClassName="intro-sound-button"
    >
      <SocialShare />
      <SpaceShipOneLinkCentered />
      <div className="flex-centered">
        <div className="you-win-score">Score: {score}</div>
      </div>
      <div className="flex-centered">
        <RestartButton />
      </div>
    </VideoBG>
  )
}
