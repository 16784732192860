import * as React from "react";
import { useStore } from '../contexts/store';

import './NavBar.css';

import Challenger from './Challenger';

type NavBarProp = {
  levelScore: number;
};

export default (props: NavBarProp) => {
  const [{challenger, beat, score}] = useStore();

  return (
    <div className="navbar">
      <div className="navbar-score">
        Score: {props.levelScore + score}
      </div>
      {
        challenger && beat ? <Challenger name={challenger} score={beat} /> : null
      }
    </div>
  );
};
