import * as React from "react";

import './Challenger.css'

type ChallengerProp = {
  name: string;
  score: number;
}

export default (props: ChallengerProp) => {
  return (
    <div className="challenger-score">
      Beat {props.name}'s score: {props.score}
    </div>
  );
}
