import React from 'react';
import VideoBG from './VideoBG';
import ImageBG from './ImageBG';
import NameForm from './NameForm';
import { SpaceShipOneLinkForIntro } from './SpaceshipOneLink';
import { SkipButton } from './IntroButtons.js';
import Levels from './Levels';
import '../App.css';
import { mixpanelTrack } from '../utils/mixpanel';
import { useStore } from '../contexts/store';
import IntroImg from '../assets/Godzillion.png';
import IntroShots2 from '../assets/IntroShots2.mp4';
import EnterNameBG from '../assets/ScreenEnterYourName.png';

// NOTE Update whenever adding another component to @screenArt
const MixpanelIdentifiers = [
  'launch-screen',
  'Go To Intro Video',
  'Go To Enter Details',
  'Go To Game Levels',
]

export default function Intro() {
  const [activeVideo, setActiveVideo] = React.useState(0);
  const [appState] = useStore();

  const nextVideo = () => {
    const nextVideo = activeVideo + 1;
    mixpanelTrack(appState, 'intro-videos', {
      advanceToVideo: nextVideo,
      eventDetails: MixpanelIdentifiers[nextVideo],
    });
    setActiveVideo(nextVideo);
  };

  const screenArt = [

    <ImageBG
      name={IntroImg}
      type="image/png"
      onClick={nextVideo}
    >
      <SpaceShipOneLinkForIntro />
    </ImageBG>,
    <VideoBG
      movie={IntroShots2}
      loop={false}
      type="video/mp4"
      soundBtnClassName="intro-sound-button"
      onClick={nextVideo}
      onEnded={nextVideo}
    >
      <SkipButton />
    </VideoBG>
    ,
    <ImageBG
      name={EnterNameBG}
      type="image/png"
      children={<NameForm onClick={nextVideo}/>}
    />,
    <Levels />
  ];

  const backgrounds = screenArt.map((asset, idx) => {
    return (
      idx === activeVideo && (
        <div key={idx}>{asset}</div>
      )
    );
  });
  return (<div>{backgrounds}</div>);
}
